import { useAsyncCallback } from '@orus.eu/pharaoh'
import { useNavigate } from '@tanstack/react-router'
import { useState, type ReactElement } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { PendingSubscriptionsListContent } from '../common/backoffice-pending-subscriptions/pending-subscriptions-list-content'

export default function PlatformPendingSubscriptionsPage(): ReactElement {
  const [creating, setCreating] = useState(false)
  const navigate = useNavigate()

  const { isLoading, data, error } = trpcReact.subscriptions.listPendingSubscriptionsSummaries.useQuery()

  const createSubscription = useAsyncCallback(async () => {
    setCreating(true)
    const subscriptionId = await trpc.subscriptions.createSubscription.mutate({})
    void navigate({ to: '/bak/v2-pending-subscriptions/$subscriptionId', params: { subscriptionId } })
  }, [navigate])

  return (
    <PendingSubscriptionsListContent
      isCreating={creating}
      createSubscription={createSubscription}
      isSubscriptionListLoading={isLoading}
      hasSubscriptionListError={!!error}
      subscriptionList={data}
    />
  )
}
